import { useDyteMeeting, useDyteSelector } from '@dytesdk/react-web-core';
import { useCallback, useEffect, useRef, useState } from 'react';
import DyteAudioHandler from 'utils/DyteAudioHandler';

const DyteAudio = () => {
  const { meeting } = useDyteMeeting();

  // States
  const [isMuted, setIsMuted] = useState<boolean>(true);

  // Redux
  const joinedParticipants = useDyteSelector((m) => m.participants.joined.toArray());
  const audioHandlerRef = useRef<DyteAudioHandler | null>(null);

  useEffect(() => {
    audioHandlerRef.current = new DyteAudioHandler();
    return () => {
      if (audioHandlerRef.current) {
        audioHandlerRef.current.dispose();
        audioHandlerRef.current = null;
      }
    };
  }, []);

  const audioUpdateListener = useCallback(
    ({
      id,
      audioEnabled,
      audioTrack,
    }: {
      id: string;
      audioEnabled: boolean;
      audioTrack: MediaStreamTrack;
    }) => {
      const audioId = `audio-${id}`;
      if (audioHandlerRef && audioHandlerRef.current) {
        if (audioEnabled && audioTrack != null) {
          audioHandlerRef.current.addTrack(audioId, audioTrack);
        } else {
          audioHandlerRef.current.removeTrack(audioId);
        }
      }
    },
    [audioHandlerRef],
  );

  useEffect(() => {
    for (const participant of joinedParticipants) {
      audioUpdateListener(participant);
    }
  }, [audioUpdateListener, joinedParticipants]);

  useEffect(() => {
    const participantLeftListener = ({ id }: { id: string }) => {
      if (audioHandlerRef && audioHandlerRef.current) {
        audioHandlerRef.current.removeTrack(`audio-${id}`);
        audioHandlerRef.current.removeTrack(`screenshare-${id}`);
      }
    };

    const screenShareUpdateListener = ({
      id,
      screenShareEnabled,
      screenShareTracks,
    }: {
      id: string;
      screenShareEnabled: boolean;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      screenShareTracks: any;
    }) => {
      const audioId = `screenshare-${id}`;
      if (audioHandlerRef && audioHandlerRef.current) {
        if (screenShareEnabled && screenShareTracks.audio != null) {
          audioHandlerRef.current.addTrack(audioId, screenShareTracks.audio);
        } else {
          audioHandlerRef.current.removeTrack(audioId);
        }
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const deviceUpdateListener = ({ device }: any) => {
      if (device.kind === 'audiooutput') {
        if (audioHandlerRef && audioHandlerRef.current) {
          audioHandlerRef.current.setDevice(device.deviceId);
        }
      }
    };

    meeting.participants.joined.addListener('audioUpdate', audioUpdateListener);
    meeting.participants.joined.addListener('screenShareUpdate', screenShareUpdateListener);
    meeting.participants.joined.addListener('participantLeft', participantLeftListener);
    meeting.self.addListener('deviceUpdate', deviceUpdateListener);
    return () => {
      meeting.participants.joined.removeListener('audioUpdate', audioUpdateListener);
      meeting.participants.joined.removeListener('screenShareUpdate', screenShareUpdateListener);
      meeting.participants.joined.removeListener('participantLeft', participantLeftListener);
      meeting.self.removeListener('deviceUpdate', deviceUpdateListener);
    };
  }, [meeting]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const { data } = event;
      if (event.data?.type === 'AUDIO_STATUS') {
        setIsMuted(data?.payload);
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    if (audioHandlerRef && audioHandlerRef.current) {
      if (isMuted) {
        // Mute the complete audio tag
        audioHandlerRef.current.muteAudioTag();
      } else {
        // Unmute the complete audio tag
        audioHandlerRef.current.unmuteAudioTag();
      }
    }
  }, [isMuted]);

  return null;
};

export default DyteAudio;

/// <reference types="vite/client" />

// Declare the types for the environment variables
declare global {
  interface ImportMetaEnv {
    VITE_MORPHCAST_ACTIVE: string;
    VITE_ENTROPIK_API_KEY: string;
    VITE_ENTROPIK_USERNAME: string;
    VITE_APP_MODE: string;
    VITE_VAD_SOCKET_SERVER_URL: string;
  }

  interface ImportMeta {
    readonly env: ImportMetaEnv;
  }
}

export const MORPHCAST_ACTIVE = import.meta.env.VITE_MORPHCAST_ACTIVE;
export const EMOTION_API_KEY = import.meta.env.VITE_ENTROPIK_API_KEY;
export const EMOTION_USERNAME = import.meta.env.VITE_ENTROPIK_USERNAME;
export const NODE_ENV = import.meta.env.VITE_APP_MODE;
export const VAD_SOCKET_SERVER_URL = import.meta.env.VITE_VAD_SOCKET_SERVER_URL;
export const WHITEBOARD_PLUGIN_ID = import.meta.env.VITE_BASE_WHITEBOARD_PLUGIN_ID || '';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PluginStatus, PluginStatusPayload } from 'types/sliceTypes';

const initialState: PluginStatus = {
  slide: { active: false, show: false },
  whiteboard: { active: false, show: false },
};

export const pluginStatusStates = createSlice({
  name: 'pluginStatusStates',
  initialState,
  reducers: {
    setPluginStatus: (state, action: PayloadAction<PluginStatusPayload>) => {
      const { pluginStatus } = action.payload;
      state.slide = pluginStatus.slide;
      state.whiteboard = pluginStatus.whiteboard;
    },
  },
  selectors: {
    selectSlideStatus: (state) => state?.slide,
    selectWhiteBoardStatus: (state) => state?.whiteboard,
  },
});

export const { setPluginStatus } = pluginStatusStates.actions;

// Define typed selectors
export const { selectSlideStatus, selectWhiteBoardStatus } = pluginStatusStates.selectors;

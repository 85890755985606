import { Socket } from 'socket.io-client';
import { useRef, useEffect, useMemo } from 'react';
import useMeeting from './useMeeting';
import { VAD_SOCKET_SERVER_URL } from '../configs/config';
import { datadogLogs } from '@datadog/browser-logs';

// Track total retries per participant
const globalRetryAttempts = new Map<string, number>();

interface TalkTimeData {
  participantId: string;
  total_talk_time: number;
  total_session_duration: number;
  recent_talk_time: number;
  from_timestamp: number;
  to_timestamp: number;
  transcribed_speech: string;
}

interface AudioBuffer {
  data: Float32Array;
  position: number;
}

const SAMPLE_RATE = 48000;
const BUFFER_SIZE = SAMPLE_RATE * 10; // 10 seconds of audio
const API_ENDPOINT = VAD_SOCKET_SERVER_URL || 'http://localhost:5000';
const MAX_RETRIES = 3;
const RETRY_INTERVAL = 15000;

export const useStudentTalkTime = (socket: Socket | undefined) => {
  const audioContextRef = useRef<AudioContext | null>(null);
  const setupInProgressRef = useRef(false);
  const initRef = useRef(false);
  const { classId, joinedParticipants } = useMeeting();
  const audioBuffers = useRef(new Map<string, AudioBuffer>());
  const processingTimers = useRef(new Map<string, NodeJS.Timeout>());
  const isProcessing = useRef(new Map<string, boolean>());
  const processorNodes = useRef(new Map<string, AudioWorkletNode>());
  const processedParticipantsRef = useRef(new Set<string>());

  const initializeBuffer = (participantId: string) => {
    if (!audioBuffers.current.has(participantId)) {
      datadogLogs.logger.info(`Initializing buffer for participantId: ${participantId}`);
      audioBuffers.current.set(participantId, {
        data: new Float32Array(BUFFER_SIZE),
        position: 0,
      });
    }
  };

  const processBufferImmediate = async (participantId: string, bufferData: Float32Array) => {
    try {
      isProcessing.current.set(participantId, true);
      datadogLogs.logger.info(`Processing buffer - participantId: ${participantId}, bufferLength: ${bufferData.length}`);
  
      const [userType, classIdPart, userId] = participantId.split('-');
  
      const payload = {
        audio_data: Array.from(bufferData),
        session_id: participantId,
        sample_rate: SAMPLE_RATE,
        user_type: userType,
        class_id: classIdPart,
        user_id: userId,
      };
      datadogLogs.logger.info(`Sending request to VAD - participantId: ${participantId}, payloadSize: ${payload.audio_data.length}`);
  
      const response = await fetch(`${API_ENDPOINT}/process-audio`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, body: ${errorText}`);
      }
  
      const data: TalkTimeData = await response.json();
  
      if (socket?.connected) {
        socket.emit('talkTimeData', { data });
      }
    } catch (error) {
      datadogLogs.logger.info(`Failed to process audio buffer - participantId: ${participantId}, error: ${error instanceof Error ? error.message : String(error)}`, {
        endpoint: API_ENDPOINT,
        errorType: error instanceof Error ? error.name : 'Unknown',
        errorStack: error instanceof Error ? error.stack : undefined
      });
  
      if (error instanceof TypeError && error.message.includes('fetch')) {
        datadogLogs.logger.warn(`Network error encountered - participantId: ${participantId}, will retry on next buffer`);
      }
    } finally {
      isProcessing.current.set(participantId, false);
    }
  };

  const processAudioTrack = (
    participantId: string,
    audioTrack: MediaStreamTrack,
    audioContext: AudioContext,
  ) => {
    try {
      datadogLogs.logger.info(`Attempting to process audio track - participantId: ${participantId}`);
      
      if (!audioTrack.enabled || audioTrack.readyState !== 'live') {
        throw new Error('Audio track not ready');
      }

      initializeBuffer(participantId);
  
      const mediaStream = new MediaStream([audioTrack]);
      const source = audioContext.createMediaStreamSource(mediaStream);
      
      const processor = new AudioWorkletNode(audioContext, 'audio-processor', {
        numberOfInputs: 1,
        numberOfOutputs: 1,
        channelCount: 1,
        channelCountMode: 'explicit',
        channelInterpretation: 'discrete',
        processorOptions: {
          sampleRate: SAMPLE_RATE
        }
      });

      processorNodes.current.set(participantId, processor);
      
      processor.port.onmessage = (event) => {
        const audioData = event.data;
        if (!Array.isArray(audioData) && !(audioData instanceof Float32Array)) {
          datadogLogs.logger.warn(`Invalid audio data received - participantId: ${participantId}`);
          return;
        }
        const float32Data = audioData instanceof Float32Array ? audioData : new Float32Array(audioData);
        void processBufferImmediate(participantId, float32Data);
      };
  
      source.connect(processor);
      
      // On successful processing, clear retry count and mark as processed
      globalRetryAttempts.delete(participantId);
      processedParticipantsRef.current.add(participantId);
      datadogLogs.logger.info(`Successfully processed audio track - participantId: ${participantId}`);
    } catch (error) {
      datadogLogs.logger.info(`Failed to process audio track - participantId: ${participantId}, error: ${error instanceof Error ? error.message : String(error)}`);
      scheduleRetry(participantId);
    }
  };

  const scheduleRetry = (participantId: string) => {
    const currentAttempts = globalRetryAttempts.get(participantId) || 0;
    const nextAttempt = currentAttempts + 1;
    
    if (nextAttempt <= MAX_RETRIES) {
      datadogLogs.logger.info(`Scheduling retry ${nextAttempt}/${MAX_RETRIES} - participantId: ${participantId}`);
      globalRetryAttempts.set(participantId, nextAttempt);

      setTimeout(() => {
        const participant = joinedParticipants.find(p => {
          const userType = p.presetName === 'group_call_host' ? 'tutor' : 'student';
          return `${userType}-${classId}-${p.customParticipantId?.split('-').pop()}` === participantId;
        });

        if (participant?.audioTrack && audioContextRef.current) {
          processAudioTrack(participantId, participant.audioTrack, audioContextRef.current);
        } else {
          datadogLogs.logger.info(`Retry ${nextAttempt} failed - participantId: ${participantId}, audioTrack: ${!!participant?.audioTrack}, audioContext: ${!!audioContextRef.current}`);
          scheduleRetry(participantId);
        }
      }, RETRY_INTERVAL);
    } else {
      datadogLogs.logger.info(`Max retries (${MAX_RETRIES}) exceeded - participantId: ${participantId}`);
      globalRetryAttempts.delete(participantId);
    }
  };

  const participantIds = useMemo(() => 
    joinedParticipants.map(participant => {
      const { presetName, customParticipantId } = participant;
      const userType = presetName === 'group_call_host' ? 'tutor' : 'student';
      return `${userType}-${classId}-${customParticipantId?.split('-').pop()}`;
    }),
    [joinedParticipants.map(p => p.customParticipantId).join(','), classId]
  );

  useEffect(() => {
    if (joinedParticipants.length === 0) {
      datadogLogs.logger.info(`No participants, cleaning up - classId: ${classId}`);
      processorNodes.current.clear();
      processingTimers.current.clear();
      audioBuffers.current.clear();
      isProcessing.current.clear();
      processedParticipantsRef.current.clear();
      return;
    }
  
    const currentParticipantIds = new Set(
      joinedParticipants.map(participant => {
        const { presetName, customParticipantId } = participant;
        const userType = presetName === 'group_call_host' ? 'tutor' : 'student';
        return `${userType}-${classId}-${customParticipantId?.split('-').pop()}`;
      })
    );
  
    Array.from(processorNodes.current.keys()).forEach(participantId => {
      if (!currentParticipantIds.has(participantId)) {
        try {
          const node = processorNodes.current.get(participantId);
          if (node) {
            node.disconnect();
            node.port.close();
            processorNodes.current.delete(participantId);
            processingTimers.current.delete(participantId);
            audioBuffers.current.delete(participantId);
            isProcessing.current.delete(participantId);
            processedParticipantsRef.current.delete(participantId);
            globalRetryAttempts.delete(participantId);
            datadogLogs.logger.info(`Cleaned up departed participant - participantId: ${participantId}`);
          }
        } catch (error) {
          datadogLogs.logger.info(`Error cleaning up departed participant - participantId: ${participantId}, error: ${error instanceof Error ? error.message : String(error)}`);
        }
      }
    });
  
    if (initRef.current) {
      datadogLogs.logger.info(`Setup already initialized - classId: ${classId}`);
      return;
    }
  
    initRef.current = true;
    datadogLogs.logger.info(`ActiveSpeaker mounting - classId: ${classId}, hasSocket: ${!!socket}, participantsCount: ${joinedParticipants.length}`);
  
    const setupAudio = async () => {
      if (setupInProgressRef.current) {
        datadogLogs.logger.info(`Setup already in progress, skipping - classId: ${classId}`);
        return;
      }
  
      try {
        setupInProgressRef.current = true;
        datadogLogs.logger.info(`Starting audio setup - classId: ${classId}`);
  
        if (!audioContextRef.current || audioContextRef.current.state === 'closed') {
          datadogLogs.logger.info(`Creating new AudioContext - classId: ${classId}`);
          audioContextRef.current = new window.AudioContext({ sampleRate: SAMPLE_RATE });
        }
  
        if (audioContextRef.current.state === 'suspended') {
          await audioContextRef.current.resume();
          datadogLogs.logger.info(`AudioContext resumed - classId: ${classId}`);
        }
  
        await audioContextRef.current.audioWorklet.addModule('/audio-processor.js');
        datadogLogs.logger.info(`Audio worklet loaded - classId: ${classId}`);
  
        joinedParticipants.forEach((participant) => {
          const { presetName, customParticipantId, audioTrack } = participant;
          const userType = presetName === 'group_call_host' ? 'tutor' : 'student';
          const participantId = `${userType}-${classId}-${customParticipantId?.split('-').pop()}`;
  
          if (processedParticipantsRef.current.has(participantId)) {
            datadogLogs.logger.info(`Participant already processed - participantId: ${participantId}`);
            return;
          }
  
          if (audioTrack && audioContextRef.current) {
            processAudioTrack(participantId, audioTrack, audioContextRef.current);
          } else {
            datadogLogs.logger.warn(`No audio track available - participantId: ${participantId}`);
            scheduleRetry(participantId);
          }
        });
      } catch (error) {
        datadogLogs.logger.info(`Error in setupAudio - classId: ${classId}, error: ${error instanceof Error ? error.message : String(error)}`);
      } finally {
        setupInProgressRef.current = false;
      }
    };
  
    const setupTimeout = setTimeout(setupAudio, 1000);
  
    return () => {
      clearTimeout(setupTimeout);
      
      if (initRef.current) {
        initRef.current = false;
  
        processorNodes.current.forEach((node, participantId) => {
          try {
            node.disconnect();
            node.port.close();
            datadogLogs.logger.info(`Disconnected processor node - participantId: ${participantId}`);
          } catch (error) {
            datadogLogs.logger.info(`Error cleaning up processor node - participantId: ${participantId}, error: ${error instanceof Error ? error.message : String(error)}`);
          }
        });
  
        if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
          datadogLogs.logger.info(`Closing AudioContext - classId: ${classId}, state: ${audioContextRef.current.state}`);
          audioContextRef.current.close().catch((error) => {
            datadogLogs.logger.info(`Error closing audio context - classId: ${classId}, error: ${error instanceof Error ? error.message : String(error)}`);
          });
        }
  
        processorNodes.current.clear();
        processingTimers.current.clear();
        audioBuffers.current.clear();
        isProcessing.current.clear();
        processedParticipantsRef.current.clear();
        
        // Only clear retry attempts for participants that are no longer present
        const currentParticipantIds = new Set(participantIds);
        for (const [participantId] of globalRetryAttempts) {
          if (!currentParticipantIds.has(participantId)) {
            globalRetryAttempts.delete(participantId);
            datadogLogs.logger.info(`Cleared retry attempts for departed participant - participantId: ${participantId}`);
          }
        }
        
        datadogLogs.logger.info(`Cleanup completed - classId: ${classId}`);
      }
    };
  }, [participantIds.length]);
};
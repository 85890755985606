import DyteClient from '@dytesdk/web-core';
import { useSearchParams } from 'react-router-dom';

import DyteAudio from 'components/DyteAudio';
import { DyteParticipantsAudio } from '@dytesdk/react-ui-kit';

const MeetingAudio = ({ meeting }: { meeting: DyteClient }) => {
  const [searchParams] = useSearchParams();
  const forTutorManager = searchParams.get('forTM')?.toLowerCase() === 'true';

  return <>{forTutorManager ? <DyteAudio /> : <DyteParticipantsAudio meeting={meeting} />}</>;
};

export default MeetingAudio;

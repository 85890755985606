import 'katex/dist/katex.min.css';
import React from 'react';
import Latex from 'react-latex-next';

import { QuestionProps } from 'types/common';

export const Question: React.FC<QuestionProps> = ({ content }) => {
  return (
    <span>
      {content?.map((item, index) => {
        switch (item?.type) {
          case 'latex':
            return <Latex key={index}>{`$${item.content}$`}</Latex>;
          case 'break':
            return <br key={index} />;
          case 'text':
            return <span key={index}>{item.content}</span>;
          case 'image':
            return <img className='inline w-100' key={index} src={item.content} alt='' />;
          default:
            return null;
        }
      })}
    </span>
  );
};

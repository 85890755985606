import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useEffect, useState } from 'react';

import { AttentionType, IAttention } from 'types';
import './attention.css';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function Attention({ socket, meeting }: IAttention) {
  const [clientToAttention, setClientToAttention] = useState<{
    [key: string]: AttentionType;
  }>({});
  useEffect(() => {
    socket?.on('serverToClient', (data: AttentionType) => {
      setClientToAttention((prev) => ({
        ...prev,
        [data.clientId]: data,
      }));
    });

    return () => {
      socket?.removeAllListeners('serverToClient');
    };
  }, [socket]);

  const getClientNameFromId = (clientId: string) => {
    const participant = meeting.participants.joined
      .toArray()
      .find((participant) => participant.customParticipantId === clientId);
    return participant?.name;
  };
  const getString = (num: number, decimals: number = 0) => {
    return num && num.toFixed(decimals);
  };

  const getTimeFromISOString = (isoString?: string) => {
    return isoString && dayjs(isoString).tz('Asia/Kolkata').format('HH:mm:ss:SSS');
  };

  return (
    <div className='attention'>
      {Object.keys(clientToAttention).map((key) => {
        return (
          <div className='client-holder' key={clientToAttention[key].clientId}>
            <p>{getClientNameFromId(clientToAttention[key].clientId)}</p>
            <div className='attention-values'>
              <div className='attention-raw-values'>
                <p>Pitch: {getString(clientToAttention[key].pitch, 2)}</p>
                <p>Roll: {getString(clientToAttention[key].roll, 2)}</p>
                <p>Yaw: {getString(clientToAttention[key].yaw, 2)}</p>
              </div>
              <div className='attention-derived-values'>
                <p>Attention Value: {getString(clientToAttention[key].attentionValue)}</p>
                <p>Attentionn Stage: {clientToAttention[key].attentionStage}</p>
                <p>Attention Level: {getString(clientToAttention[key].attentionLevel)}</p>
              </div>
            </div>
            <p>{getTimeFromISOString(clientToAttention[key].timestampTo)}</p>
          </div>
        );
      })}
    </div>
  );
}

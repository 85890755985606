import { startCase } from 'lodash';

import { selectEvaluationData } from 'store/slice/notesHtmlData.slice';

import { useAppSelector } from 'hooks';
import { Question } from './Questions';
import './scrollbar.css';

export default function Evaluation() {
  const evaluationData = useAppSelector(selectEvaluationData);

  return (
    <div className='flex flex-col flex-1 mx-2 my-5 bg-[#1B1F3B] rounded-lg max-h-[calc(100vh-13vh)]'>
      {/* Header section */}
      <div className='sticky top-0 z-10 p-6 bg-[#243154] shadow-md rounded-t-md'>
        <h2 className='text-2xl font-bold text-white'>
          {startCase(evaluationData?.evaluationType) || 'Evaluation Questions'}
        </h2>
      </div>

      <div className='max-h-full px-6 py-4 space-y-6 overflow-y-auto custom-scrollbar'>
        {evaluationData?.questions?.length > 0 ? (
          evaluationData?.questions?.map((question, index) => (
            <div
              key={question?._id}
              className='p-6 transition-all duration-300 ease-in-out bg-[#2A3C5E] rounded-lg shadow-md hover:shadow-lg hover:bg-[#3B4B72]'
            >
              <h3 className='mb-4 text-lg font-semibold text-white'>Question {index + 1}</h3>
              <div className='relative text-white'>
                <Question content={question?.question_description || []} />
              </div>
            </div>
          ))
        ) : (
          <div className='p-6 bg-[#2A3C5E] rounded-lg shadow-md'>
            <p className='text-white'>No questions available.</p>
          </div>
        )}
      </div>
    </div>
  );
}

import { isEmpty } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Socket } from 'socket.io-client';

import {
  selectActiveNotesHtmlData,
  selectEvaluationData,
  setActiveNotesHtmlData,
  setEvaluation,
  setSlide,
  setStep,
} from 'store/slice/notesHtmlData.slice';
import { setPluginStatus } from 'store/slice/pluginStatus.slice';

import { useAppDispatch, useAppSelector } from 'hooks/store';
import useMeeting from 'hooks/useMeeting';
import { htmlSlidesType, IPluginStatusSocketEvent, slideStepType, StateKeys } from 'types/common';
import { IStartEvaluation } from 'types/sliceTypes';

import { ScreenShare, Plugins, Evaluation, HtmlContent } from 'components';

const AllContent = ({ socket }: { socket: Socket | undefined }) => {
  const dispatch = useAppDispatch();
  const { classId, screenShareEnabled, screenShareHost } = useMeeting();

  // Redux
  const activeNotesHtmlData = useAppSelector(selectActiveNotesHtmlData);
  const getEvaluationData = useAppSelector(selectEvaluationData);
  const [htmlLink, setHtmlLink] = useState<string>('');

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const sendJumpToAnimMessage = useCallback(
    (step: number, slide: number) => {
      if (iframeRef.current) {
        iframeRef.current.contentWindow?.postMessage(
          {
            type: 'uprio_custom',
            fn: 'JumpToAnim',
            params: [step, slide, true],
          },
          '*',
        );
      }
    },
    [iframeRef],
  );

  // Effect to update htmlLink when state changes
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setHtmlLink(activeNotesHtmlData.htmlLink?.[activeNotesHtmlData?.currentState]);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [activeNotesHtmlData]);

  useEffect(() => {
    // only called when assistant rejoined
    socket?.emit('rejoiningAssistant');
    socket?.on('rejoiningAssistantSendingDataToClient', (data) => {
      dispatch(setEvaluation(data?.activeEvaluation));
    });
    socket?.on('htmlSlidesEveToClient', (data: htmlSlidesType) => {
      dispatch(setActiveNotesHtmlData(data));
    });
    socket?.on(
      'pluginStatusEveToClient',
      (data: { pluginStatus: IPluginStatusSocketEvent; currentState: StateKeys }) => {
        dispatch(setPluginStatus({ pluginStatus: data?.pluginStatus?.[data?.currentState] }));
      },
    );
    socket?.on('tutorJoinedToClient', (data: slideStepType) => {
      dispatch(setStep(data?.step?.[activeNotesHtmlData?.currentState]));
      dispatch(setSlide(data?.slide?.[activeNotesHtmlData?.currentState]));
    });
    socket?.on('evaluationEveToClient', (data: IStartEvaluation) => {
      dispatch(setEvaluation(data));
    });

    return () => {
      socket?.removeAllListeners('htmlSlidesEveToClient');
      socket?.removeAllListeners('pluginStatusEveToClient');
      socket?.removeAllListeners('rejoiningAssistantSendingDataToClient');
      socket?.removeAllListeners('tutorJoinedToClient');
      socket?.removeAllListeners('evaluationEveToClient');
    };
  }, [socket]);

  useEffect(() => {
    socket?.emit('refreshHuddleState', { classId: classId });
    return () => {
      socket?.removeAllListeners('refreshHuddleState');
    };
  }, [socket]);

  // Send JumpToAnim message when slide or step changes
  useEffect(() => {
    sendJumpToAnimMessage(activeNotesHtmlData?.step, activeNotesHtmlData?.slide);
  }, [activeNotesHtmlData?.slide, activeNotesHtmlData?.step, sendJumpToAnimMessage]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const data = event?.data || {};
      const { evt, type } = data || {};
      if (type === 'uprio_custom') {
        if (evt === 'init_completed') {
          sendJumpToAnimMessage(activeNotesHtmlData?.step, activeNotesHtmlData?.slide);
        }
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [activeNotesHtmlData?.step, activeNotesHtmlData?.slide]); // added this dependency coz it was giving 0

  return (
    <>
      {screenShareHost && screenShareEnabled ? (
        <ScreenShare />
      ) : (
        <div className='flex flex-col flex-1 h-full overflow-hidden'>
          <Plugins />
          <div>
            {!isEmpty(getEvaluationData?.questions) ? (
              <Evaluation />
            ) : (
              <HtmlContent
                socket={socket}
                iframeRef={iframeRef}
                classId={classId}
                currentState={activeNotesHtmlData?.currentState}
                htmlLink={htmlLink}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AllContent;

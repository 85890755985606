import { useState, useEffect } from 'react';
import { Socket, io } from 'socket.io-client';
import { useSearchParams } from 'react-router-dom';
import { datadogLogs } from '@datadog/browser-logs';

const useSocketConnection = (props: { classId: string | null }) => {
  const [socket, setSocket] = useState<Socket>();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const id = '';
    const classId = props.classId || searchParams.get('classId');
    const userType = 'recorder';
    const socketServerUrl = import.meta.env.VITE_SOCKET_SERVER_URL;
    datadogLogs.logger.info(
      `classId: ${classId}, userType: ${userType} trying to connect socket ${socketServerUrl}`,
      { userType, classId },
    );
    if (userType && classId && socketServerUrl) {
      const socketFinal = io(socketServerUrl, {
        query: {
          id,
          userType,
          classId,
        },
        transports: ['websocket'],
      });

      setSocket(socketFinal);
      socketFinal.on('connect', () => {
        datadogLogs.logger.info(`classId: ${classId}, userType: ${userType} Socket Connected`, {
          userType,
          classId,
        });
      });
    }
    return () => {
      socket?.disconnect();
    };
  }, []);

  return socket;
};

export default useSocketConnection;

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import useMeeting from 'hooks/useMeeting';
import { IEmotion } from 'types';
import '../Attention/attention.css';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function Emotion({ detectedEmotionsMap }: IEmotion) {
  const { meeting } = useMeeting();

  const getClientNameFromId = (clientId: number) => {
    const userId = String(clientId);
    const participant = meeting.participants.joined
      .toArray()
      .find((participant) => participant.customParticipantId === userId);
    return participant?.name;
  };
  const getString = (num: number, decimals: number = 0) => {
    return num && num.toFixed(decimals);
  };

  const getTimeFromISOString = (isoString?: string) => {
    return isoString && dayjs(isoString).tz('Asia/Kolkata').format('HH:mm:ss:SSS');
  };

  return (
    <div className='attention'>
      {Object.keys(detectedEmotionsMap).map((key) => {
        return (
          <div className='client-holder' key={detectedEmotionsMap[key].userId}>
            <p>{getClientNameFromId(detectedEmotionsMap[key].userId)}</p>
            <div className='attention-values'>
              <div className='attention-derived-values'>
                <p>Arousal: {getString(detectedEmotionsMap[key].arousal, 3)}</p>
                <p>Valence: {getString(detectedEmotionsMap[key].valence, 3)}</p>
              </div>
            </div>
            <p>{getTimeFromISOString(detectedEmotionsMap[key].timestamp)}</p>
          </div>
        );
      })}
    </div>
  );
}

// src/store/index.ts

import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineSlices } from '@reduxjs/toolkit';
import { classNotesState } from './slice/notesHtmlData.slice';
import { pluginStatusStates } from './slice/pluginStatus.slice';

// Combine slices (add your slices here)
const slices = [classNotesState, pluginStatusStates]; // Add your slice imports here

// Combine reducers
const rootReducer = combineSlices(...slices);

// Define RootState type
export type RootState = ReturnType<typeof rootReducer>;

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['classNotesState', 'pluginStatusStates'], // Specify slices to persist if any
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const makeStore = (preloadedState: any) => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
    preloadedState,
  });

  const persister = persistStore(store);
  return { store, persister };
};

export const { store, persister } = makeStore({});

// Type definitions
export type AppStore = typeof store;
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ThunkReturnType = void> = ThunkAction<
  ThunkReturnType,
  RootState,
  unknown,
  Action
>;

import DyteClient from '@dytesdk/web-core';
import { Socket } from 'socket.io-client';
import { htmlSlidesType } from 'types/common';

export interface PluginStatus {
  slide: { active: boolean; show: boolean };
  whiteboard: { active: boolean; show: boolean };
}

export interface PluginStatusPayload {
  pluginStatus: PluginStatus;
}

export interface IClassNotesLinks {
  activeNotesHtmlData: htmlSlidesType;
  startEvaluation: IStartEvaluation;
  classId: string;
}

export interface IStartEvaluation {
  type: string;
  slideNo: number;
  evaluationId: string;
  evaluateAt: string;
  evaluationType: string;
  questions: IQuestion[];
}

export interface IQuestion {
  elementDetails: IElementDetails;
  question_identifier: string;
  answer: [];
  hint_for_student: [];
  belongs_to_element: number;
  question_categories: IQuestionCategories;
  option2: IOption2[];
  option4: [];
  correct_option: ICorrectOption[];
  notes_for_tutor: [];
  question_description: IQuestionDescription[];
  option3: IOption3[];
  _id: string;
  option1: IOption1[];
  elementId: string;
}

export interface IElementDetails {
  _id: string;
  element_name: string;
  referenceId: string;
}

export interface IQuestionCategories {
  _id: string;
  question_category_description: string;
  question_category_reference_id: string;
  steps: IStep[];
}

export interface IStep {
  step: string;
  _key: string;
  is_critical: boolean;
}

export interface IOption2 {
  type: string;
  content?: string;
}

export interface ICorrectOption {
  type: string;
  content: string;
}

export interface IQuestionDescription {
  type: 'text' | 'break' | 'latex' | 'image';
  content?: string;
}

export interface IOption3 {
  type: string;
  content?: string;
}

export interface IOption1 {
  type: string;
  content?: string;
}

export interface AttentionType {
  classId: string;
  clientId: string;
  pitch: number;
  roll: number;
  yaw: number;
  attentionLevel: number;
  attentionStage: string;
  attentionValue: number;
  timestampFrom: string;
  timestampTo: string;
}

export interface IAttention {
  socket: Socket | undefined;
  meeting: DyteClient;
}

export interface IEmotion {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  detectedEmotionsMap: Record<string, any>;
}

export interface IRecordingView {
  roomName: string;
  authToken: string;
  config: MeetingConfig;
  classId: string | null;
  apiBase: string | null;
}

export interface WatermarkSize {
  width?: number;
  height?: number;
}

export enum WatermarkPosition {
  TopLeft = 'left top',
  TopRight = 'right top',
  BottomLeft = 'left bottom',
  BottomRight = 'right bottom',
}

export interface WatermarkConfig {
  url: string;
  position: WatermarkPosition;
  size: WatermarkSize;
  opacity: number;
  enabled: boolean;
}

export interface IWaterMark {
  config: WatermarkConfig;
}

export interface MeetingConfig {
  uiKit: boolean;
  watermark: WatermarkConfig;
  waitTimeMs: number;
}

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { htmlSlidesType } from 'types/common';
import { IClassNotesLinks, IStartEvaluation } from 'types/sliceTypes';

const initialState: IClassNotesLinks = {
  activeNotesHtmlData: {
    htmlLink: {
      teach: '',
      coach_basic: '',
      coach_intermediate: '',
      coach_advanced: '',
    },
    slide: 1,
    step: 0,
    currentState: 'teach',
    studentID: 0,
  },
  startEvaluation: {
    evaluationId: '',
    slideNo: 1,
    type: '',
    evaluationType: '',
    evaluateAt: '',
    questions: [],
  },
  classId: '',
};

export const classNotesState = createSlice({
  name: 'classNotesState',
  initialState,
  reducers: {
    setActiveNotesHtmlData: (state, action: PayloadAction<htmlSlidesType>) => {
      state.activeNotesHtmlData = action.payload;
    },
    setStep: (state, action: PayloadAction<number>) => {
      state.activeNotesHtmlData.step = action.payload;
    },
    setSlide: (state, action: PayloadAction<number>) => {
      state.activeNotesHtmlData.slide = action.payload;
    },
    setEvaluation: (state, action: PayloadAction<IStartEvaluation>) => {
      state.startEvaluation = action.payload;
    },
    resetClassContentStates: (state, action: PayloadAction<string>) => {
      if (state.classId !== action.payload) {
        state.activeNotesHtmlData = initialState.activeNotesHtmlData;
        state.startEvaluation = initialState.startEvaluation;
      }
      state.classId = action.payload;
    },
  },
  selectors: {
    selectActiveNotesHtmlData: (state) => state?.activeNotesHtmlData,
    selectEvaluationData: (state) => state?.startEvaluation,
  },
});

export const {
  setActiveNotesHtmlData,
  setStep,
  setSlide,
  setEvaluation,
  resetClassContentStates: resetClassNotesState,
} = classNotesState.actions;

export const { selectActiveNotesHtmlData, selectEvaluationData } = classNotesState.selectors;

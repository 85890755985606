import { DyteScreenshareView } from '@dytesdk/react-ui-kit';
import useMeeting from 'hooks/useMeeting';

export default function ScreenShare() {
  const { screenShareHost, meeting } = useMeeting();

  return (
    <>
      {screenShareHost && (
        <div className='w-full h-full'>
          <DyteScreenshareView participant={screenShareHost} meeting={meeting} />
        </div>
      )}
    </>
  );
}

import { isEmpty } from 'lodash';
import { useEffect } from 'react';

import { DytePluginMain } from '@dytesdk/react-ui-kit';
import useMeeting from 'hooks/useMeeting';
import { useAppSelector } from 'hooks/store';
import { selectWhiteBoardStatus } from 'store/slice/pluginStatus.slice';

const Plugins = () => {
  const { meeting, activePlugins, whiteBoardPlugin, tutors } = useMeeting();
  const selectedWhiteBoardStatus = useAppSelector(selectWhiteBoardStatus);

  useEffect(() => {
    if (
      activePlugins.length > 0 &&
      (meeting?.meta?.selfActiveTab?.id === 'null' || !meeting?.meta?.selfActiveTab?.id)
    ) {
      meeting?.meta?.setSelfActiveTab(
        {
          type: 'plugin',
          id: activePlugins[0].id,
        },
        0,
      );
    }
  }, [activePlugins, meeting?.meta]);

  useEffect(() => {
    whiteBoardPlugin?.on('ready', () => {
      if (isEmpty(tutors)) return;
      whiteBoardPlugin?.sendData({
        eventName: 'config',
        data: {
          eventName: 'config',
          follow: tutors?.[0]?.id,
          autoScale: true,
        },
      });
    });
    return () => {
      whiteBoardPlugin?.removeAllListeners('ready');
    };
  }, [whiteBoardPlugin, tutors]);

  return (
    <>
      {activePlugins?.map((plugin) => {
        return (
          <div
            key={plugin.id}
            style={{
              transition: 'opacity 0.3s ease',
            }}
            className={`${selectedWhiteBoardStatus?.show ? 'h-[90vh] p-2' : 'h-[0vh] opacity-0'}`}
          >
            <DytePluginMain meeting={meeting} plugin={plugin} />
          </div>
        );
      })}
    </>
  );
};

export default Plugins;
